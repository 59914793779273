<template>
    <el-dialog
        :title="mode == 'add' ? '新增工具' : '编辑工具'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
            <el-form-item label="工具名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入工具名称"></el-input>
            </el-form-item>
            <el-form-item label="简介">
                <el-input type="textarea" :rows="2" v-model="form.introduction" placeholder="请输入简介"></el-input>
            </el-form-item>
            <el-form-item label="icon图标" prop="icon" class="upload-form-items">
                <el-upload
                    class="upload-demo"
                    list-type="picture-card"
                    action="none"
                    accept=".png,.jpg,.jpe,.jpeg,.jif,.jfif,.jfi,.PNG,.JPG,.JPEG,.JPE,.svg"
                    :multiple="false"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleUploadFile">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <div v-if="form.icon">
                    <img :src="form.icon | uploadFileUrl()" alt="" v-if="form.icon.indexOf('http') == -1">
                    <img :src="form.icon" alt="" v-else>
                </div>
            </el-form-item>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="格式分类" prop="categoryFormat">
                        <el-select v-model="form.categoryFormat" placeholder="请选择格式分类">
                            <el-option
                            v-for="item in typeOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="功能分类" prop="categoryUse">
                        <el-select v-model="form.categoryUse" placeholder="请选择功能分类">
                            <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="是否展示即将上线" prop="futureFlag">
                        <el-radio-group v-model="form.futureFlag">
                            <el-radio :label="true">是</el-radio>
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="排序" prop="seq">
                        <el-input-number v-model="form.seq" :min="1" size="medium"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="是否上线" prop="onlineFlag">
                        <el-radio-group v-model="form.onlineFlag">
                            <el-radio :label="true">上线</el-radio>
                            <el-radio :label="false">下线</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否免费" prop="freeFlag">
                        <el-radio-group v-model="form.freeFlag">
                            <el-radio :label="true">免费</el-radio>
                            <el-radio :label="false">付费</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="描述">
                <el-input type="textarea" :rows="2" v-model="form.description" placeholder="请输入描述"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                name: '',
                introduction: '',
                description: '',
                categoryFormat: '',
                categoryUse: '',
                seq: 1,
                onlineFlag: true,
                freeFlag: true,
                icon: null,
                futureFlag: true
            },
            rules: {
                name: [
                    { required: true, message: '请输入工具名称', trigger: 'blur' }
                ],
                categoryFormat: [
                    { required: true, message: '请选择格式分类', trigger: 'change' }
                ],
                categoryUse: [
                    { required: true, message: '请选择功能分类', trigger: 'change' }
                ],
                seq: [
                    { required: true, message: '请选择排序序号', trigger: 'change' }
                ],
                onlineFlag: [
                    { required: true, message: '请选择是否上线', trigger: 'change' }
                ],
                freeFlag: [
                    { required: true, message: '请选择是否免费', trigger: 'change' }
                ],
                icon: [
                    { required: true, message: '请上传图标', trigger: 'change' }
                ],
                futureFlag: [
                    { required: true, message: '请选择是否展示即将上线敬请期待', trigger: 'change' }
                ]
            },
            options: [],
            typeOptions: []
        };
    },
    methods: {
        show(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    name: '',
                    introduction: '',
                    description: '',
                    categoryFormat: '',
                    categoryUse: '',
                    seq: 1,
                    onlineFlag: true,
                    freeFlag: true,
                    icon: null,
                    futureFlag: true
                }
            } else {
                this.getDetail(row.id);
            }

            this.getType();
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        getType() {
            this.$store.dispatch('manage/public', {
                query: {
                    dimension: 1,
                    pageNum: 1
                },
                requestInterfaceName: 'getTooltypeList'
            }).then(res=>{
                if(res.code == 0) {
                    this.options = res.data.records;
                }
            })

            this.$store.dispatch('manage/public', {
                query: {
                    dimension: 0,
                    pageNum: 1
                },
                requestInterfaceName: 'getTooltypeList'
            }).then(res=>{
                if(res.code == 0) {
                    this.typeOptions = res.data.records;
                }
            })
        },
        getDetail(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'toolDetailInfo'
            }).then(res=>{
                if(res.code == 0) {
                    this.form = {
                        id: res.data.id,
                        name: res.data.name,
                        introduction: res.data.introduction,
                        description: res.data.description,
                        categoryFormat: res.data.categoryFormat,
                        categoryUse: res.data.categoryUse,
                        seq: res.data.seq,
                        onlineFlag: res.data.onlineFlag,
                        freeFlag: res.data.freeFlag,
                        icon: res.data.icon,
                        futureFlag: res.data.futureFlag
                    }
                }
            })
        },
        // 上传
        handleUploadFile(file, fileList) {
            // 获取中文件后缀
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const fileFormat = ['png', 'jpg', 'jpe', 'jpeg', 'jif', 'jfif', 'jfi', 'PNG', 'JPG', 'JPEG', 'JPE', 'svg'];
            if (fileFormat.indexOf(fileSuffix) === -1) {
                this.$message.error('上传图标只能是png、jpg、jpe、jpeg、jif、jfif、jfi、PNG、JPG、JPEG、JPE、svg格式');
                return false;
            }
            // 请求接口
            var fd = new FormData();
            fd.append('file', file.raw);
         
            this.$store.dispatch('manage/public', {
                query: fd,
                requestInterfaceName: 'uploadFile'
            }).then(res=>{
                if(res.code == 0) {
                    this.$message.success('上传成功');
                    this.form.icon = res.data;
                } else {
                    this.$message.error(res.message);
                    this.form.icon = null;
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.icon = this.form.icon.slice(this.form.icon.indexOf('dev'));
                    
                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addToolData' : 'editToolData',
                        listInterfaceName: 'getToolList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                            this.$parent.formInline.pageNum = 1;
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .upload-form-items {
       .el-form-item__content {
            display: flex;
            img {
                width: 100px;
                height: 100px;
                margin-left: 20px;
                border-radius: 3px;
            }
       }
    }
    /deep/ .el-upload {
        width: 120px;
        height: 120px;
        line-height: 124px;
    }
}
</style>